import React from 'react';
class Leave extends React.Component {
  constructor() {
    super()
    window.close()
  }

  // recordAttendance(classId, batchId, accessToken, leave) {
  //   fetch(process.env.REACT_APP_LOCAL_API_URL + "users/v1/api/attendance", {
  //     method: 'POST',
  //     params: { 'Authoriztion': 'Bearer ' + accessToken },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + accessToken
  //     },
  //     body: JSON.stringify({
  //       idBatch: batchId,
  //       idClass: classId,
  //       leave: leave
  //     })
  //   }).then(res => res.json())
  //     .then(response => {
  //     })
  // }
 
  render() {
    return (
      <div>leaving....</div>
    );
  }
}

export default Leave;
