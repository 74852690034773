import React from 'react';
class Forbidden extends React.Component {
    render() {
        const check = process.env.React_APP_ACTIVE_URL
        console.log(check)
        return (
            <div style={{textAlign: "center"}}>
                Invalid Link...
       
            </div>
        
        );
    }
} 

export default Forbidden;
