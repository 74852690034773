import App from "./App";
import {React} from 'react';
import {Route, Routes } from 'react-router-dom';
import Leave from "./Leave";
import Forbidden from "./Forbidden";

function AppRouting() {
    
      return (
        <Routes>
          <Route  path="/leave" element={<Leave />} />
          <Route path="/meeting" element={<App/>} />
          <Route path="/invalid-link" element={<Forbidden/>} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!!</p>
              </main>
            }
          />
        </Routes>
      );
    
  }
  
  export default AppRouting;
  
