import React from "react";

import "./App.css";
import * as SockJs from "sockjs-client";
import * as Stomp from "stomp-websocket";
import { ZoomMtg } from "@zoom/meetingsdk";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

class App extends React.Component {
  pauseAndPlayRecSubscription;
  iconSubscription;
  classId;
  instituteId;
  accessToken;
  isJoinClass;

  createAndSubscribeSocket(userId) {
    const socket = new SockJs(process.env.REACT_APP_SOCKET_URL);
    const stompClient = Stomp.over(socket);
    const header = {
      Authorization: "Bearer " + this.accessToken,
    };
    stompClient.connect(header, function (frame) {
      console.log(frame);
      stompClient.subscribe("/queue/logout/" + userId, function (msg) {
        alert("You are already logout the session.");
        setTimeout(() => {
          window.close();
        }, 5000);
      });
    });
  }

  async onParticipantExit() {
    try {
      await fetch(
        process.env.REACT_APP_API_URL +
          "users/v1/api/participants/exit/" +
          this.classId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      console.log("Participant exited successfully");
      this.clearSessionId();
    } catch (error) {
      console.error("Error while exiting participant:", error);
    }
  }

  //1:
  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    if (document.referrer.includes("stg.nrichlearning.co.in/")) {
      let params = window.location.search.split("?")[1].split("&", 4);
      this.classId = params[0];
      this.instituteId = params[1];
      this.accessToken = params[2];
      this.initializeMeeting();
      return;
    }
    window.location.assign("/invalid-link");
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      this.onParticipantExit();
      this.clearSessionId();
    }
  };
  handleUnload = () => {
    this.onParticipantExit();
    this.clearSessionId();
  };

  handleBeforeUnload = () => {
    this.onParticipantExit();
    this.clearSessionId();
  };

  componentWillUnmount() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }
  generateSessionId() {
    // Generate a random string
    const randomString = Math.random().toString(36).substr(2, 9);

    // Append the current timestamp
    const timestamp = Date.now();

    // Combine the random string and timestamp to create a session ID
    const sessionId = `${randomString}-${timestamp}`;

    return sessionId;
  }
  clearSessionId() {
    localStorage.removeItem("session_id");
  }
  //2:
  initializeMeeting() {
    this.createAndSubscribeSocket(this.parseJwt(this.accessToken).user_id);
    fetch(
      process.env.REACT_APP_API_URL +
        "users/v1/api/getMeetingDetails?idClassSchedule=" +
        this.classId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.accessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 201) {
          this.startMeeting(
            response.body.id,
            response.body.name,
            response.body.hostEmail,
            response.body.password,
            response.body.role,
            response.body.userId,
            response.body.signature
          );
          this.sessionOut(response.body.endTime);
        } else if (response.status === 401) {
          this.closeTab("You are already logout.");
        } else {
          this.closeTab("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error while initializing meeting:", error);
        this.closeTab("Error while initializing meeting");
      });
  }

  //4:
  startMeeting(
    meetingNumber,
    userName,
    userEmail,
    passWord,
    role,
    userId,
    signature
  ) {
    console.log(signature);
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: process.env.REACT_APP_LEAVE_URL,
      disableInvite: true,
      disableZoomLogo: true,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName == null ? "Test" : userName,
          sdkKey: process.env.REACT_APP_SDK_KEY,
          customerKey: userId + "/" + this.classId,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success);
            this.isJoinClass = true;
            this.onParticipantJoining();
            const appSignalElement = document.getElementById("app-signal");
            if (appSignalElement) {
              appSignalElement.style.display = "none";
            } else {
              console.error("Element with ID 'app-signal' not found");
            }
            this.removePauseAndPlayRecordingBtn();
            this.removeRecordingIcons();
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  checkUserAlreadyJoinedOrNot() {
    const storedSessionId = localStorage.getItem("session_id");

    if (storedSessionId) {
      console.log(storedSessionId);
      this.closeTab(
        "You are already joined this class from another browser or window."
      );
    } else {
      const newSessionId = this.generateSessionId();
      localStorage.setItem("session_id", newSessionId);
    }
  }
  closeTab(msg) {
    alert(msg);
    setTimeout(() => {
      window.close();
    }, 2000);
  }

  async onParticipantJoining() {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        "users/v1/api/participants/entry/" +
        this.classId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.accessToken,
        },
        body: {},
      }
    );

    const responseData = await response.json(); // Use await here

    console.log(responseData);

    if (responseData.status === 400) {
      this.closeTab(responseData.message);
    }
  }

  removePauseAndPlayRecordingBtn() {
    this.pauseAndPlayRecSubscription = setInterval(() => {
      var htmlCollection = document.getElementsByClassName(
        "footer-button-pause-stop-recording"
      );
      if (htmlCollection && htmlCollection.length !== 0) {
        for (let element of htmlCollection) {
          element.remove();
        }
        clearInterval(this.pauseAndPlayRecSubscription);
      }
    }, 500);
  }

  removeRecordingIcons() {
    this.iconSubscription = setInterval(() => {
      var htmlCollection = document.getElementsByClassName(
        "meeting-info-container__wrapper"
      );
      if (htmlCollection && htmlCollection.length !== 0) {
        for (let element of htmlCollection) {
          element.remove();
        }
        clearInterval(this.iconSubscription);
      }
    }, 500);
  }

  parseJwt(token) {
    console.log(token);
    console.log(token.split(".")[1]);
    var base64Payload = token.split(".")[1];
    var payload = Buffer.from(base64Payload, "base64");
    return JSON.parse(payload.toString());
  }

  sessionOut(endTime) {
    setTimeout(() => {
      alert("Session Timeout.");
      setTimeout(() => {
        window.close();
      }, 5000);
    }, endTime * 1000);
  }

  render() {
    return (
      <div className="App" style={{ textAlign: "center" }}>
        <p>Loading...</p>
      </div>
    );
  }
}

export default App;
